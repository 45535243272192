<template>
  <div>
    <b-card-actions
        ref="refreshCard"
        action-refresh
        action-create
        disable-loading-on-refresh
        @refresh="getItems"
        @create="hRedirect('apps-bookingtimes-new')"
        :loaded.sync="loaded"
    >
      <table-header :filter="filter" :per-page.sync="perPage"/>
      <b-overlay :show="!loaded" rounded="sm">
        <b-table
            ref="refBookingtimesListTable"
            class="position-relative"
            responsive
            primary-key="id"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :filter="filter.input"
            @filtered="onFiltered"
            show-empty
            :empty-text="$t('No matching records found')">
          <template #head()="data">
            {{ $t(data.label) }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
                :to="{
              name: 'apps-bookingtimes-edit',
              params:{
                id: data.item.id },
              }"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      type="submit"
                      size="sm"
            >
              {{ $t('Edit') }}
            </b-button>

            <b-button v-on:click="deleteItem(data.item.id)" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" type="submit" size="sm">
              {{ $t('Delete') }}
            </b-button>
          </template>
        </b-table>
      </b-overlay>

      <table-footer
          :current-page.sync="currentPage"
          :per-page="perPage"
          :length="totalRows"
      />

    </b-card-actions>
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BOverlay
} from 'bootstrap-vue'
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import BCardActions from '@core/components/b-card-actions/BCardActions'
import TableHeader from '@/views/components/TableHeader'
import TableFooter from '@/views/components/TableFooter'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    BCardActions,
    TableHeader,
    TableFooter,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: {
        input: null
      },
      tableColumns: [
        {key: 'weekday',
          sortable: true,
          label: 'weekday',
          formatter: this.getWeekDayName
        },
        {key: 'starttime', sortable: true, label: 'starttime'},
        {key: 'endtime', sortable: true, label: 'endtime'},
        {key: 'actions', label: 'Actions', class: 'table-action-buttons'}
      ],
      items: [],
    }
  },
  computed: {},
  mounted() {
    this.getItems()
  },
  methods: {
    dataMeta() {
      return {
        from: this.perPage.value * (this.currentPage.value - 1) + (this.perPage ? 1 : 0),
        to: this.perPage.value * (this.currentPage.value - 1) + this.perPage,
        of: this.items.length,
      }
    },
    getItems() {
      this.items = []
      this.loaded = false

      this.helperGetItems(`/bookingtimes`);
    },
    deleteItem(id) {
      this.helperDeleteItem(
          `/bookingtimes/${id}`,
      )

      this.$store.dispatch('GET_USER_ONBOARDING')
    },

    getWeekDayName(value, key, item) {
      let weekday = [
        { value: 1, text: "Sunday" },
        { value: 2, text: "Monday" },
        { value: 3, text: "Tuesday" },
        { value: 4, text: "Wednesday" },
        { value: 5, text: "Thursday" },
        { value: 6, text: "Friday" },
        { value: 7, text: "Saturday" },
      ];
      let weekDayName;
      weekday.forEach(function (item) {
        if (item.value == value) weekDayName = item.text;
      });
      return this.$t(weekDayName)
    }


  },

  watch: {
    '$route.params': {
      handler(newValue) {
        const {bookingtimeid} = newValue

        this.getItems(bookingtimeid)
      },
      immediate: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">

</style>
